define("discourse/plugins/discourse-automation/discourse/components/placeholders-list", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "discourse/components/d-button", "@ember/template-factory", "@ember/component"], function (_exports, _component, _helper, _object, _dButton, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _class2;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let PlaceholdersList = _exports.default = (_class = (_class2 = class PlaceholdersList extends _component.default {
    copyPlaceholder(placeholder1) {
      this.args.onCopy(`${this.args.currentValue} %%${placeholder1.toUpperCase()}%%`);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="placeholders-list">
        {{#each @placeholders as |placeholder|}}
          <DButton
            @translatedLabel={{placeholder}}
            class="placeholder-item"
            @action={{fn this.copyPlaceholder placeholder}}
          />
        {{/each}}
      </div>
    
  */
  {
    "id": "n3g7uKEA",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"placeholders-list\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"        \"],[8,[32,0],[[24,0,\"placeholder-item\"]],[[\"@translatedLabel\",\"@action\"],[[30,2],[28,[32,1],[[30,0,[\"copyPlaceholder\"]],[30,2]],null]]],null],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@placeholders\",\"placeholder\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/discourse-automation/discourse/components/placeholders-list.js",
    "scope": () => [_dButton.default, _helper.fn],
    "isStrictMode": true
  }), _class2), _class2), (_applyDecoratedDescriptor(_class.prototype, "copyPlaceholder", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "copyPlaceholder"), _class.prototype)), _class);
});